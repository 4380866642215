var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-auto contentsWidth mt-4"},[_vm._m(0),_c('div',{staticClass:"contentsWidth mailArea p-3"},[_c('b-row',{staticClass:"mt-4 mx-4"},[_c('div',{staticClass:"label p-0 mb-0 mr-10px"},[_c('label',{staticClass:"mb-0 bold"},[_vm._v("送信先")])]),_c('div',{staticClass:"mb-1 mainArea"},[_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.MailTargetModal",modifiers:{"MailTargetModal":true}}],staticClass:"line"},[_vm._v(" "+_vm._s(_vm.userList.length)+"件 ")]),(_vm.existsInvalid)?_c('p',{staticClass:"notice mb-0"},[_vm._v(" システムからのメールが受信されなかったため、メール送信がブロックされているユーザーがいます。 ")]):_vm._e()],1)]),_c('hr'),_c('b-row',{staticClass:"mt-4 mx-4"},[_c('div',{staticClass:"label p-0 mb-0 mr-10px"},[_c('label',{staticClass:"mb-0 bold"},[_vm._v("添付ファイル")])]),_c('div',{staticClass:"mb-1 mainArea"},[_c('b-col',{staticClass:"p-0"},[_c('b-input-group',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.fileList.length === 0),expression:"fileList.length === 0"}],staticClass:"mb-2 fileName"},[_vm._v("ファイルが選択されていません")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.fileList.length > 0),expression:"fileList.length > 0"}],staticClass:"mb-2 fileName"},_vm._l((_vm.fileList),function(file,index){return _c('p',{key:index,staticClass:"mb-0"},[_c('b-link',{on:{"click":function($event){return _vm.downloadFile(index, file.name)}}},[_vm._v(_vm._s(file.name))]),_c('img',{staticClass:"tdDeleteBtn",attrs:{"src":require("@/assets/svg/close_small.svg")},on:{"click":function($event){return _vm.deleteFile(index)}}})],1)}),0)]),_c('b-input-group',[_c('div',{class:[
              _vm.isDragging ? 'is-dragging' : '',
              'drop', 'flex', 'flexMiddle', 'flexCenter'
            ],on:{"dragover":function($event){$event.preventDefault();return _vm.toggleDrag($event, true)},"dragleave":function($event){$event.preventDefault();return _vm.toggleDrag($event, false)},"drop":function($event){$event.preventDefault();return _vm.onDrop($event)}}},[_c('span',[_c('span',{class:[
                    'dragText',
                    'fw-6']},[_vm._v("ここにファイルをドラッグ＆ドロップ または")]),_c('label',{staticClass:"fileText fw-6 selectFile",attrs:{"for":`file_1`}},[_vm._v("［ ファイルを選択 ］")]),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","id":`file_1`},on:{"change":function($event){return _vm.uplodad($event)}}})])])])],1)],1)]),_c('hr'),_c('b-row',{staticClass:"mt-4 mx-4"},[_c('div',{staticClass:"label p-0 mb-0 mr-10px"},[_c('label',{staticClass:"mb-0 bold"},[_vm._v("タイトル")])]),_c('div',{staticClass:"mb-1 mainArea"},[_c('b-form-input',{attrs:{"placeholder":"入力してください。"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)]),_c('hr'),_c('b-row',{staticClass:"mt-4 mx-4"},[_c('div',{staticClass:"label p-0 mb-0 mr-10px"},[_c('label',{staticClass:"mb-0 bold"},[_vm._v("本文")])]),_c('div',{staticClass:"mb-1 mainArea"},[_c('div',[_vm._v("{%氏名%} でユーザーの氏名、{%所属機関%} で所属機関名を表示できます。")]),_c('b-form-textarea',{attrs:{"rows":10,"placeholder":"入力してください。"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)])],1),_c('div',{staticClass:"mt-5 mb-3 flex flexCenter contentsWidth mx-auto"},[_c('b-button',{staticClass:"btn btn-lg bold",attrs:{"to":"/cms/cmsUser/list"}},[_vm._v("一覧に戻る")]),_c('b-button',{staticClass:"btn btn-primary btn-lg mr-2 bold ml-4",attrs:{"disabled":!_vm.canSend,"variant":"primary"},on:{"click":_vm.sendMail}},[_vm._v("メール送信")])],1),_c('v-mail-target-modal',{attrs:{"userList":_vm.userList,"existsInvalid":_vm.existsInvalid},on:{"update:userList":function($event){_vm.userList=$event},"update:user-list":function($event){_vm.userList=$event},"update:existsInvalid":function($event){_vm.existsInvalid=$event},"update:exists-invalid":function($event){_vm.existsInvalid=$event}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('p',{staticClass:"title mb-0"},[_vm._v("管理者 - メール送信")]),_c('hr',{staticClass:"title"})])
}]

export { render, staticRenderFns }