<template>
  <div class="mx-auto contentsWidth mt-4">
    <div class="">
      <p class="title mb-0">管理者 - メール送信</p>
      <hr class="title">
    </div>


    <div class="contentsWidth mailArea p-3">

      <b-row class="mt-4 mx-4">
        <div class="label p-0 mb-0 mr-10px">
          <label class="mb-0 bold">送信先</label>
        </div>
        <div class="mb-1 mainArea">
          <b-link class="line" v-b-modal.MailTargetModal>
            {{userList.length}}件
          </b-link>
          <p
            v-if="existsInvalid"
            class="notice mb-0">
            システムからのメールが受信されなかったため、メール送信がブロックされているユーザーがいます。
          </p>
        </div>
      </b-row>
      <hr/>

      <b-row class="mt-4 mx-4">
        <div class="label p-0 mb-0 mr-10px">
          <label class="mb-0 bold">添付ファイル</label>
        </div>
        <div class="mb-1 mainArea">
          <b-col class="p-0">
            <b-input-group>
              <span
                v-show="fileList.length === 0"
                class="mb-2 fileName">ファイルが選択されていません</span>
              <span
                v-show="fileList.length > 0"
                class="mb-2 fileName">
                <p
                  v-for="(file, index) in fileList" :key="index"
                  class="mb-0">
                  <b-link @click="downloadFile(index, file.name)">{{file.name}}</b-link>
                  <img
                    src="@/assets/svg/close_small.svg"
                    @click="deleteFile(index)"
                    class="tdDeleteBtn">
                </p>
              </span>
            </b-input-group>

            <b-input-group>
              <div
              @dragover.prevent="toggleDrag($event, true)"
              @dragleave.prevent="toggleDrag($event, false)"
              @drop.prevent="onDrop($event)"
              :class="[
                isDragging ? 'is-dragging' : '',
                'drop', 'flex', 'flexMiddle', 'flexCenter'
              ]">
                <span>
                  <span
                    :class="[
                      'dragText',
                      'fw-6']">ここにファイルをドラッグ＆ドロップ または</span>
                  <label
                    :for="`file_1`"
                    class="fileText fw-6 selectFile">［ ファイルを選択 ］</label>
                  <input type="file" style="display:none;"
                    :id="`file_1`"
                    @change="uplodad($event)"
                  >
                </span>
              </div>
            </b-input-group>

          </b-col>
        </div>
      </b-row>
      <hr/>

      <b-row class="mt-4 mx-4">
        <div class="label p-0 mb-0 mr-10px">
          <label class="mb-0 bold">タイトル</label>
        </div>
        <div class="mb-1 mainArea">
          <b-form-input
            v-model="title"
            placeholder="入力してください。"/>
        </div>
      </b-row>
      <hr/>

      <b-row class="mt-4 mx-4">
        <div class="label p-0 mb-0 mr-10px">
          <label class="mb-0 bold">本文</label>
        </div>
        <div class="mb-1 mainArea">
          <div>{%氏名%} でユーザーの氏名、{%所属機関%} で所属機関名を表示できます。</div>
          <b-form-textarea
            v-model="message"
            :rows="10"
            placeholder="入力してください。"/>
        </div>
      </b-row>

    </div>

    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        class="btn btn-lg bold"
        to="/cms/cmsUser/list">一覧に戻る</b-button>
      <b-button
        :disabled="!canSend"
        variant="primary"
        class="btn btn-primary btn-lg mr-2 bold ml-4"
        @click="sendMail">メール送信</b-button>
    </div>

    <!-- popUp -->
    <v-mail-target-modal
     :userList.sync="userList"
     :existsInvalid.sync="existsInvalid"></v-mail-target-modal>

  </div>
</template>

<script>
// import moment from 'moment';
import api from '@/modules/api';
import MailTargetModal from '@/components/cms/cmsUser/MailTargetModal.vue';
import download from '@/modules/download';


export default {
  name: 'cmsApplicationMail',
  components: {
    'v-mail-target-modal': MailTargetModal,
  },
  data() {
    return {
      isDragging: false,
      fileList: [],
      userList: [],
      existsInvalid: false,
      title: '',
      message: '',
      tempUpFileList: {},
      upFileKeys: [],
    };
  },
  computed: {
    selectedIdList() {
      return this.$store.state.cmsUserSearch.selectedIdList;
    },
    canSend() {
      const hasTitle = this.title !== '';
      const hasMessage = this.message !== '';
      const hasTarget = this.userList.length > 0;
      return hasTitle && hasMessage && hasTarget;
    },
  },
  methods: {
    async getUsers() {
      const param = {
        idList: this.selectedIdList,
      };
      const response = await api.send('/api/user/cms/mail/list', param)
        .catch((err) => {
          console.log(err);
        });
      this.userList = response.data.userList;
      this.existsInvalid = response.data.existsInvalid;
    },
    async sendMail() {
      if (!await this.confirm('現在の内容でメールを送信してよろしいですか？')) {
        return;
      }
      const params = {
        idList: this.selectedIdList,
        fileCnt: this.fileList.length,
        message: this.message,
        title: this.title,
      };
      const formData = {
        form: JSON.stringify(params),
      };
      Object.keys(this.fileList).forEach((index) => {
        formData[`file_${index}`] = this.fileList[index];
      });
      this.$store.dispatch('page/onWaiting');
      const response = await api.sendForm('/api/user/cms/mail/send', formData)
        .catch(async (err) => {
          await this.alert(err.response.data.msg);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      if (response.data.errIdList.length > 0) {
        let names = '';
        response.data.errIdList.forEach((userId) => {
          const errUser = this.userList.find((user) => {
            return user.id === userId;
          });
          names += `・${errUser.name}\n`;
        });
        await this.alert(`以下のユーザーへのメールの送信に失敗しました。\n${names}`);
      } else {
        await this.alert('選択したユーザーにメールを送信しました。');
        this.$router.push({ path: '/cms/cmsUser/list' });
      }
    },
    uplodad(event) {
      if (event.target.files.length === 0) {
        return;
      }
      const file = event.target.files[0];
      this.fileList.push(file);

      let setIndex = 1;
      if (this.upFileKeys.length !== 0) {
        const listMax = Math.max(...this.upFileKeys);
        setIndex = listMax + 1;
      }
      this.upFileKeys.push(setIndex);
    },
    deleteFile(index) {
      const itemNumber = this.upFileKeys[index];
      this.fileList.splice(index, 1);
      this.upFileKeys.splice(index, 1);
      this.$delete(this.tempUpFileList, itemNumber);
      document.getElementById('file_1').value = '';
    },
    toggleDrag(event, isDragging) {
      this.isDragging = isDragging;
    },
    onDrop(event) {
      this.isDragging = false;
      const fileList = event.target.files ? event.target.files : event.dataTransfer.files;
      // ファイルが無い時処理を中止
      if (fileList.length === 0) {
        return false;
      }
      Object.keys(fileList).forEach((key) => {
        this.fileList.push(fileList[key]);
        let setIndex = 1;
        if (this.upFileKeys.length !== 0) {
          const listMax = Math.max(...this.upFileKeys);
          setIndex = listMax + 1;
        }
        this.upFileKeys.push(setIndex);
      });
      return true;
    },
    async downloadFile(index, fileName) {
      const fileKey = this.upFileKeys[index];
      const url = '/api/cms/confupfile/fileDownload';
      this.$store.dispatch('page/onWaiting');
      if (!this.tempUpFileList[fileKey]) {
        const tempUpUrl = '/api/cms/confupfile';
        const tempParam = {
          itemNumber: JSON.stringify(fileKey),
        };
        tempParam.file = this.fileList[index];
        const tempResponse = await api.sendForm(tempUpUrl, tempParam, true)
          .catch((err) => {
            console.error(err);
          });
        if (!tempResponse) {
          this.$store.dispatch('page/offWaiting');
          await this.alert('ファイルのダウンロードに失敗しました。再度アップロードしてお試しください。');
          return;
        }
        this.$set(this.tempUpFileList, fileKey, tempResponse.data.path);
      }
      const path = this.tempUpFileList[fileKey];
      const param = {
        fileName: path,
      };
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
  },
  // ロード画面
  async created() {
    // 選択されている申請がなければ一覧に戻す
    if (this.selectedIdList.length === 0) {
      this.$router.push({ path: '/cms/cmsUser/list' });
    }
    this.$store.dispatch('page/onLoading');
    this.getUsers();
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>
  input {
    height: 50px;
  }

  .mailArea{
    background-color: #fff;
  }
  .label {
    width: 230px;
    max-width: 230px;
    height: 25px;
  }
  .mainArea {
    width: 770px;
  }

  .drop {
    height: 100px;
    width: 100%;
    background-color: #F3F3F3;
    border-color: #CCC;
    border-style: dashed;
    border-radius: 10px;
    border-width: 1px;
  }
  .dragText {
    color: #BBB;
  }
  .fileText {
    color: #0A8EA7;
  }
  .is-dragging.drop {
    background-color: #E6E6E6;
    border-color: #6E6E6E;
  }
  .is-dragging {
    color: #6E6E6E;
  }
  .selectFile {
    cursor: pointer;
  }
  .selectFile:hover {
    text-decoration: underline;
  }

  .line {
    text-decoration: underline;
  }

  .notice {
    color: #dc3545;
  }
</style>
